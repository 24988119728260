<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单查询">
              <a-input
                v-model:value.trim="where.goodsName"
                placeholder="请输入订单查询"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单时间:">
              <a-range-picker
                v-model:value="daterange"
                value-format="YYYY-MM-DD"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <!-- <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="商品上下架:">
              <a-select
                allow-clear
                placeholder="请选择商品上下架"
                v-model:value="where.goodsStatus"
              >
                <a-select-option
                  v-for="item in goodsStatus"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <!-- <div>
              <a-radio-group v-model:value="radioValue" @change="radioChange">
                <a-radio-button value="all">全部</a-radio-button>
                <a-radio-button value="onSale">出售中</a-radio-button>
                <a-radio-button value="offSale">已下架</a-radio-button>
                <a-radio-button value="soldOut">已售完</a-radio-button>
              </a-radio-group>
            </div> -->
            <!-- <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>创建商品</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button> -->
          </a-space>
        </template>
        <template #img="{ record }">
          <img :src="record.goodsMainPic" width="50" height="50" />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'order-refund',

  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '商品信息',
          dataIndex: 'goodsName'
        },
        {
          title: '商品图片',
          dataIndex: 'goodsMainPic',
          sorter: true,
          slots: { customRender: 'img' }
        },
        {
          title: '单价/数量',
          dataIndex: 'categoryName'
        },
        {
          title: '实付款',
          dataIndex: 'goodsPrice'
        },
        {
          title: '买家',
          dataIndex: 'sellingPoint',
          width: '300px',
          sorter: true
        },
        {
          title: '配送信息',
          dataIndex: 'stockNumber',
          sorter: true
        },
        {
          title: '发货状态',
          dataIndex: 'goodsStatusName',
          sorter: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '150px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: {},
      // 表格选中数据
      selection: [],
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      categoryList: [],
      // 商品名称list
      sortList: [],
      // 状态字典项
      goodsStatus: [],
      // 日期范围选择
      daterange: []
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
